$(document).ready(function() {

    var needWork = true;

    $('a#newsNav').click(function(e) {
        e.preventDefault();
        void 0;
    });

    $("#disclaimerCopy").hide();

    /*$('#skillTabs a').click(function (e) {
      e.preventDefault()
      $(this).tab('show')
    });*/

    if (needWork) {
        $('body').prepend('<div class="alert alert-success">Looking for work! Please call 07584 300286</div>');
    } else {
        $('body').prepend('<div class="alert alert-warning">Not currently looking for work!</div>');
    }

    // Disclaimer Button

    $('#disclaimerButton').click(function() {
        $('#disclaimerCopy').slideToggle('slow');
    });

});
